.showMenu {
  display: block !important;
}
.hideMenu {
  display: none !important  ;
}
.sidebar {
  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar *
  padding: 48px 0 0; /* Height of navbar *
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1); */
  margin-top: 0.5rem;
  /* margin-left: 0.5rem; */
}
.sidebar-menu-item {
  font-family: sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  color: #007bff !important;
}
.sign-out-btn {
  color: #fff !important;
}
.sign-out-li:hover {
  color: #333 !important;
}
.sign-out-btn:hover {
  color: #333 !important;
}
@media (min-width: 768px) {
  .main {
    width: 85%;
  }
  .sidebar {
    width: 15% !important;
  }
}

@media (max-width: 767.99px) {
  .sidebar {
    top: 5rem;
    width: 100%;
    display: block;
  }
  .main {
    width: 100%;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  /*height: calc(100vh - 48px);*/
  height: auto;
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
   * Navbar
   */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

/* .navbar{
    background-color: #060b26;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu{
    background-color: #060b26;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
}

.nav-menu.active{
    left: 0;
    transition: 350ms;
}

.nav-text{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    list-style: none;
    height: 60px;
}

.nav-text a{
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4 px;
}

.nav-text a:hover{
    background-color: #1a83ff;
}

.nav-menu-items{
    width: 100%;
    padding-left: 0px;
}

.nav-bar-toggle{
    background-color: #060b26;
    width: 100%;
    height: 8px;
    display: flex;
    justify-content: start;
    align-items: center;
}
span{
    margin-left: 16px;
} */
