.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.parent-portal-header {
  background-color: #fc7417;
}

.icon-box {
  display: flex;
  align-items: center;
  padding: 20px;
  background: #d7fab9;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.icon-box h3 {
  font-weight: 600;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
}

.icon-box button h3:hover {
  color: #01036f;
}

.feature:hover {
  background-color: #ebebff;
}
.width-maxContent {
  width: max-content;
}

/*
For Parent portal
*/
.app-div {
  background-image: url(img/bg_light_blue.d1ed3b7e91dd97cf54ad.jpg);
  width: 100% !important;
  font-size: 1rem;
  height: 100% !important;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/*
Parent portal styles
*/
.parent-portal-button {
  height: 80%;
  width: 75%;
  font-size: 1.5rem !important;
}

.parent-portal-input {
  height: 140%;
  width: 75%;
}

.vertical-horizontal-center-aligned {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.carousel-div {
  border-top: 20.5rem solid transparent;
  border-right: 4.75rem solid transparent;
  border-bottom: 0.15rem solid transparent;
  border-left: 4.75rem solid transparent;
  border-image: url(img/Jharokha2_orignal.png) 34% 17% 2.5% 18%;
  border-image-width: 20.5rem 4.75rem 1.25rem 4.75rem;
  border-image-outset: 0rem;
  padding-right: 0% !important;
  padding-left: 0% !important;
}
.carousel-image {
  width: 30rem;
  height: 35rem;
  background-size: cover;
  transform: scale(1);
  transition: 0.3s ease-in-out;
  padding-bottom: 1rem !important;
}
.parent-portal-home {
  background-image: url(img/ParentPortalHomePageBackground.png);
}
.alignContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.alignFooterContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.main-content-div {
  background-color: #fff;
  margin: auto;
  padding-bottom: 0;
  width: 81.25% !important;
}
.conactInfoText {
  font-size: 1.25rem !important;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: Poppins, sans-serif;
  margin-bottom: 20px;
}

.black-bottom-border {
  border-bottom: 1px solid #000;
}

.footer-copyright {
  background-color: #202020;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.AcademicCalendarContent {
  float: right;
  width: 85%;
}
.AcademicCalendarContent .rbc-calendar .rbc-event-content {
  overflow: unset !important;
}

.rightAlignedInputText {
  text-align: right;
}
/*large devices - screen width equal to or greater than 992px*/
@media only screen and (min-width: 992px) {
  .resetMargin {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .switchContent {
    width: 100%;
    float: right !important;
  }
  .rectangle {
    /* width: 100%; */
    height: 37px;
    white-space: pre;
    color: #fff;
    background-image: url(img/news_one.50f3f24d94c9b1d97bc6.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}
/*medium devices - screen width equal to or greater than 768px*/
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .resetMargin {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .rectangle {
    /* width: 100%; */
    height: 37px;
    white-space: pre;
    color: #fff;
    background-image: url(img/news_two.50374abba13fe7ec8316.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  .switchContent {
    width: 100%;
    /* padding-right: 1rem !important; */
  }
}
/*small devices - screen width equal to or greater than 576px*/
@media only screen and (max-width: 767px) and (min-width: 576px) {
  .rectangle {
    /* width: 100%; */
    height: 37px;
    white-space: pre;
    color: #fff;
    background-image: url(img/news_three.58d7840620cb2b98c80c.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}
/*extra small devices - screen width less than 576px*/
@media only screen and (max-width: 575px) {
  .rectangle {
    /* width: 100%; */
    height: 37px;
    white-space: pre;
    color: #fff;
    background-image: url(img/news_four.45ef9a4d1f972bd98eb8.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}

/*small devices - screen width equal to or smaller than 767px*/
@media only screen and (max-width: 767px) {
  .AcademicCalendarContent {
    width: 100%;
  }
  .switchContent {
    width: 100%;
    /* padding-right: 1rem !important; */
  }
  .alignContent {
    display: flex;
    flex-direction: column;
  }
  .alignFooterContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .main-content-div {
    background-color: #fff;
    margin: auto;
    padding-bottom: 0;
    width: 100% !important;
    margin: auto;
  }
}
